import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jordan-tpz/Workspace/galidog-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "utilisation-de-lapplication-mobile"
    }}>{`Utilisation de l'application mobile`}</h1>
    <p>{`L'application mobile Galidog présente 2 modes d'utilisation :`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`vocal`}</strong>{` : conçu essentiellement pour les « DV »`}</li>
      <li parentName="ul"><strong parentName="li">{`tactile`}</strong>{` : pour les accompagnateurs ne présentant pas de déficience visuelle`}</li>
    </ul>
    <h2 {...{
      "id": "à-savoir"
    }}>{`À savoir`}</h2>
    <p>{`Lorsque le mot clé est prononcé, le mode vocal est automatiquement déclenché. Pour une personne ayant toutes ses capacités visuelles, elle pourra alors apercevoir une icône micro en mouvement avec des ondes (le système attend alors que l'utilisateur parle pour enclencher une action spécifique).`}</p>
    <h2 {...{
      "id": "troubleshoting"
    }}>{`Troubleshoting`}</h2>
    <p>{`Si vous n'obtenez aucune réponse vocale du système, il est fort probable que les paramètres de votre device soient mal configurés. Ainsi, voici comment faire :`}</p>
    <ul>
      <li parentName="ul">{`allez dans les paramètres de votre téléphone`}</li>
      <li parentName="ul">{`puis dans gestion globale`}</li>
      <li parentName="ul">{`puis dans langue et saisie`}</li>
      <li parentName="ul">{`puis dans synthèse vocale (voix)`}</li>
      <li parentName="ul">{`moteur préféré : google`}</li>
      <li parentName="ul">{`langue : votre langue`}</li>
    </ul>
    <p>{`Vous l'avez compris lors du choix du moteur préféré, vous devez impérativement avoir l'application `}<strong parentName="p">{`Google`}</strong>{` d'installée et d'activée sur votre téléphone. Sans cela, l'application Galidog ne pourra pas fonctionner convenablement.`}</p>
    <blockquote>
      <p parentName="blockquote">{`L'application Google fournie par défaut un service de reconnaissance vocale qui est utilisé dans le cadre de l'application Galidog !`}</p>
    </blockquote>
    <h2 {...{
      "id": "paramétrage-de-lapplication-pour-une-utilisation-en-arrière-plan-«-mode-background-»"
    }}>{`Paramétrage de l'application pour une utilisation en arrière-plan (« mode background »)`}</h2>
    <p>{`Lors de toute nouvelle installation de l'application mobile sur un smartphone, il est préférable de suivre les procédures de configuration suivantes pour une bonne utilisation de l'application en arrière-plan :`}</p>
    <p><img alt="DialogFlowAutonomyIntent" src={require("./assets/images/background-settings.png")} /></p>
    <h2 {...{
      "id": "mode-demploi"
    }}>{`Mode d'emploi`}</h2>
    <h3 {...{
      "id": "mode-vocal"
    }}>{`Mode vocal`}</h3>
    <p>{`À ce stade, chaque commande doit être précédée du mot clé `}<strong parentName="p">{`Alexa`}</strong>{` et a un « trigger word » (mot déclencheur) qui va permettre à l'application mobile d'enclencher la bonne action.`}</p>
    <p>{`Voici les commandes disponibles actuellement et comment les activer :`}</p>
    <blockquote>
      <p parentName="blockquote">{`GÉNÉRAL / ÉCRAN D'ACCUEIL`}</p>
    </blockquote>
    <table>
  <tr>
    <th style={{
          "textAlign": "center"
        }}>Commande</th>
    <th style={{
          "textAlign": "center"
        }}>Description</th>
    <th style={{
          "textAlign": "center"
        }}>Exemple</th>
    <th style={{
          "textAlign": "center"
        }}>Trigger word</th>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Autonomie</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Indique l'autonomie de l'appareil (en %).</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>
      <ul style={{
            "listStyleType": "none"
          }}>
        <li>« Alexa, autonomie »</li>
        <li>« Alexa, quelle est mon autonomie ? »</li>
      </ul>
    </td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Autonomie</td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Géolocalisation</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Indique la position courante de l'utilisateur ainsi que l'adresse postale la plus proche (si possible).</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>« Alexa, où suis-je ? »</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Où suis-je ?</td>
  </tr>
  
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Volume - augmenter</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Augmenter le volume du téléphone.</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>« Alexa, augmenter le volume »</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Augmenter</td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Volume - diminuer</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Diminuer le volume du téléphone.</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>« Alexa, diminuer le volume »</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Diminuer</td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Listing des commande</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Indique l'ensemble des commandes disponibles.</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>« Alexa, aide »</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Aide</td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Confirmer une action</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Confirmer un choix.</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>« Alexa, oui »</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Oui</td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Annuler une action</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Annuler un choix.</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>
      <ul style={{
            "listStyleType": "none"
          }}>
        <li>« Alexa, non »</li>
        <li>« Alexa, annuler »</li>
      </ul>
    </td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>
      <ul style={{
            "listStyleType": "none"
          }}>
        <li>Non</li>
        <li>Annuler</li>
      </ul>
    </td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Heure</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Indique l'heure courante.</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>
      <ul style={{
            "listStyleType": "none"
          }}>
        <li>« Alexa, heure »</li>
        <li>« Alexa, quelle heure est-il ? »</li>
      </ul>
    </td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Heure</td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Mémorisation</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Active le mode mémorisation (enregistrement d'un trajet).</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>« Alexa, mémorisation »</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>
      <ul style={{
            "listStyleType": "none"
          }}>
        <li>Mémorisation</li>
        <li>Memorisation</li>
      </ul>
    </td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Listing des trajets</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Indique les trajets enregistrés de l'utilisateur.</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>« Alexa, donner liste trajets »</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>
      <ul style={{
            "listStyleType": "none"
          }}>
        <li>Donner liste trajet</li>
        <li>Donner trajet</li>
      </ul>
    </td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Supprimer un trajet</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Supprimer un trajet enregistré.</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>
      <ul style={{
            "listStyleType": "none"
          }}>
        <li>« Alexa, supprimer trajet [nom] »</li>
        <li>« Alexa, supprimer trajet boulangerie »</li>
      </ul>
    </td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Supprimer trajet [nom]</td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Résumer un trajet</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Résumer un trajet enregistré.</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>
      <ul style={{
            "listStyleType": "none"
          }}>
        <li>« Alexa, résumer trajet [nom] »</li>
        <li>« Alexa, résumer trajet boulangerie »</li>
      </ul>
    </td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Résumer trajet [nom]</td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Listing des adresses</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Indique les adresses de l'utilisateur.</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>« Alexa, donner liste adresses »</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>
      <ul style={{
            "listStyleType": "none"
          }}>
        <li>Donner liste adresses</li>
        <li>Donner adresses</li>
      </ul>
    </td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Supprimer une adresse</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Supprimer une adresse enregistrée.</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>
      <ul style={{
            "listStyleType": "none"
          }}>
        <li>« Alexa, supprimer adresse [nom] »</li>
        <li>« Alexa, supprimer adresse boulangerie »</li>
      </ul>
    </td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Supprimer adresse [nom]</td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Rejouer</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Rejouer un trajet enregistré.</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>
      <ul style={{
            "listStyleType": "none"
          }}>
        <li>« Alexa, rejouer boulangerie »</li>
        <li>« Alexa, rejouer [nom] »</li>
      </ul>
    </td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Rejouer [nom]</td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Quitter</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Interrompre une action et/ou réinitialiser le système.</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>« Alexa, quitter »</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Quitter</td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Pause</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Suspendre un processus en cours.</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>« Alexa, pause »</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Pause</td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Reprendre - suite à une pause</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Reprendre un processus après sa suspension.</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>« Alexa, reprendre »</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Reprendre</td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Description</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Décrire chaque étape d'un trajet.</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>« Alexa, description »</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Description</td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Quitter un trajet > sans enregistrer</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Répondre « Non » à la question « Voulez-vous enregistrer votre trajet ? ».</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>« Alexa, non »</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Non</td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Quitter un trajet > avec enregistrement</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Répondre « Oui » à la question « Voulez-vous enregistrer votre trajet ? ».</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>« Alexa, oui »</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Oui</td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Quitter un trajet > avec enregistrement > nom</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Répondre à la question « Quel nom voulez-vous donner à votre trajet ? ».</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>« Alexa, boulangerie »</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>[nom]</td>
  </tr> 
    </table>
    <p><em parentName="p"><ins>{`À savoir :`}</ins></em></p>
    <ul>
      <li parentName="ul">{`Lorsque vous demandez votre position, le système vous répondra : « Vous êtes `}{`[position]`}{`, `}{`[adresse postale la plus proche]`}{` ».`}</li>
      <li parentName="ul">{`En `}<strong parentName="li">{`tactile`}</strong>{`, la commande « Description » est accessible uniquement depuis l'écran « Récapitulatif du trajet » et lorsque celui-ci n'a pas été lancé. `}</li>
      <li parentName="ul">{`En `}<strong parentName="li">{`vocal`}</strong>{`, la commande « Description » est accessible après le calibrage.`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`MODE MÉMORISATION`}</p>
    </blockquote>
    <table>
  <tr>
    <th style={{
          "textAlign": "center"
        }}>Commande</th>
    <th style={{
          "textAlign": "center"
        }}>Description</th>
    <th style={{
          "textAlign": "center"
        }}>Exemple</th>
    <th style={{
          "textAlign": "center"
        }}>Trigger word</th>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Donner un ordre</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Mémoriser une manoeuvre lors de la mémorisation.</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>
      <ul style={{
            "listStyleType": "none"
          }}>
        <li>« Alexa, tourner à droite »</li>
        <li>« Alexa, tourné à droite »</li>
        <li>« Alexa, tournée à droite »</li>
        <li>« Alexa, tournez à droite »</li>
        <li>« Alexa, tourner à gauche »</li>
        <li>« Alexa, tourné à gauche »</li>
        <li>« Alexa, tournée à gauche »</li>
        <li>« Alexa, tournez à gauche »</li>
        <li>« Alexa, tout droit »</li>
      </ul>
    </td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>
      <ul style={{
            "listStyleType": "none"
          }}>
        <li>Tourner à droite</li>
        <li>Tourner à gauche</li>
        <li>Tout droit</li>
      </ul>
    </td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Donner un POI</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Mémoriser un POI lors de la mémorisation.</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>
      <ul style={{
            "listStyleType": "none"
          }}>
        <li>« Alexa, point d'intérêt [nom] »</li>
        <li>« Alexa, point d'intérêt panneau stop »</li>
      </ul>
    </td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>
      <ul style={{
            "listStyleType": "none"
          }}>
        <li>Point d'intérêt</li>
        <li>Point d'interet</li>
        <li>Point d'intéret</li>
        <li>Point d'interêt</li>
      </ul>
    </td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Donner une traversée</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Mémoriser une traversée lors de la mémorisation.</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>
      <ul style={{
            "listStyleType": "none"
          }}>
        <li>« Alexa, traversée [nom] »</li>
        <li>« Alexa, traversée grande rue »</li>
      </ul>
    </td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>
      <ul style={{
            "listStyleType": "none"
          }}>
        <li>Traverser [nom]</li>
        <li>Traversez [nom]</li>
        <li>Traversé [nom]</li>
        <li>Traversée [nom]</li>
      </ul>
    </td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Débuter</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Débuter la mémorisation.</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>« Alexa, début enregistrement »</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>
      <ul style={{
            "listStyleType": "none"
          }}>
        <li>Début enregistrement</li>
        <li>Debut enregistrement</li>
      </ul>
    </td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Terminer</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Terminer la mémorisation.</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>« Alexa, fin enregistrement »</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Fin enregistrement</td>
  </tr>
    </table>
    <blockquote>
      <p parentName="blockquote">{`MODE NAVIGATION`}</p>
    </blockquote>
    <table>
  <tr>
    <th style={{
          "textAlign": "center"
        }}>Commande</th>
    <th style={{
          "textAlign": "center"
        }}>Description</th>
    <th style={{
          "textAlign": "center"
        }}>Exemple</th>
    <th style={{
          "textAlign": "center"
        }}>Trigger word</th>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Navigation - calcul d'un itinéraire</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Activer le mode navigation pour se rendre à un endroit précis.</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>« Alexa, navigation »</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Navigation</td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Navigation - calcul d'un itinéraire > poursuivre</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Répondre « Oui » à la question « Voulez-vous démarrer ce trajet ? ».</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>« Alexa, oui »</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Oui</td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Navigation - calcul d'un itinéraire > annuler</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Répondre « Non » à la question « Voulez-vous démarrer ce trajet ? ».</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>« Alexa, non »</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Non</td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Navigation - calcul d'un itinéraire > description</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Répondre « Description » à la question « Voulez-vous démarrer ce trajet ? ».</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>« Alexa, description »</td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>Description</td>
  </tr>
    </table>
    <h3 {...{
      "id": "navigation"
    }}>{`Navigation`}</h3>
    <blockquote>
      <p parentName="blockquote">{`Sans fichier adresse (seulement les trajets)`}</p>
    </blockquote>
    <p>{`Le « fichier adresse » comprend toutes les adresses enregistrées d’un utilisateur, dans le `}<a parentName="p" {...{
        "href": "http://api.galidog.org/"
      }}>{`back-office`}</a>{`.
Lorsque vous vous connecterez pour la première fois sur l’application mobile, ce « fichier adresse » n’est pas présent : les adresses ne sont donc pas présentes au sein de l’application mobile.`}</p>
    <p>{`De ce fait, lorsque vous lancez une « navigation », l'application mobile vous propose 2 options pour l’adresse de départ :`}</p>
    <ul>
      <li parentName="ul">{`adresse postale (donnez une adresse sous le format suivant : adresse + code postal + ville)`}</li>
      <li parentName="ul">{`position (votre position courante)`}</li>
    </ul>
    <p>{`Pour l’adresse de destination, l'application mobile vous demandera l’adresse postale.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Avec le fichier adresse`}</p>
    </blockquote>
    <p>{`Si vous souhaitez avoir vos trajets pré-enregistrés et pouvoir les rejouer, téléchargez alors le « fichier adresse » (si ce dernier a bien été créé via le `}<a parentName="p" {...{
        "href": "http://api.galidog.org/"
      }}>{`back-office`}</a>{`).`}</p>
    <p>{`Pour le retrouver :`}</p>
    <ul>
      <li parentName="ul">{`cliquez sur « Réglages » en haut à droite de l'accueil :`}</li>
    </ul>
    <p><img alt="DialogFlowAutonomyIntent" src={require("./assets/images/app-preferences-icon.jpeg")} /></p>
    <ul>
      <li parentName="ul">{`cliquez sur « Fichier d'adresses » dans le menu qui s'affiche :`}</li>
    </ul>
    <p><img alt="DialogFlowAutonomyIntent" src={require("./assets/images/app-adresses-file-menu.jpeg")} /></p>
    <p><em parentName="p"><ins>{`Conséquence :`}</ins>{` le fichier adresse est désormais enregistré sur votre smartphone sous le nom « adresses.txt ».`}</em></p>
    <blockquote>
      <p parentName="blockquote">{`Adresse de départ `}</p>
    </blockquote>
    <p>{`Lorsque vous lancez une « navigation », l'application mobile vous propose 3 options pour l’adresse de départ :`}</p>
    <ul>
      <li parentName="ul">{`Adresse postale (donnez une adresse sous le format suivant : adresse + code postal + ville)`}</li>
      <li parentName="ul">{`Adresse répertoire (la liste de vos adresses enregistrées s’affiche et vous citez celle que vous souhaitez utiliser)`}</li>
      <li parentName="ul">{`Position (votre position courante)`}</li>
    </ul>
    <p>{`Pour l’adresse de destination, l'application mobile vous proposera :`}</p>
    <ul>
      <li parentName="ul">{`Adresse postale (donnez une adresse sous le format suivant : adresse + code postal + ville)`}</li>
      <li parentName="ul">{`Adresse répertoire (la liste de vos adresses enregistrées s’affiche et vous citez celle que vous souhaitez utiliser)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      